import { Container, Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { palette } from '../../../styles/palette';

const NeoNavbar = styled(Navbar)`
  background: linear-gradient(to bottom right, ${palette.black}, ${palette.darkgrey});
  padding: 5px 10px;
  margin-bottom: 15px;
  border-radius: 10px;
  box-shadow: 6px 6px 10px 0 #0d0d0d, -4px -4px 8px 0 #1c1c1c;
  text-decoration: none;
`;

const LinkNavBrand = styled(Navbar.Brand)`
  font-family: 'Indie Flower', cursive;
  font-size: 1.4rem;
  color: ${palette.aqua} !important;
`;

const CustomNavLink = styled(Nav.Link)`
  padding-left: 5px;
  padding: 10px;
  color: ${palette.white};
  text-decoration: none;

  &:hover {
    border-radius: 10px;
    background: linear-gradient(to top left, ${palette.black}, ${palette.darkgrey});
    box-shadow: 3px 3px 6px 0 #0d0d0d, -2px -2px 4px 0 #1c1c1c;
    color: ${palette.teal};
  }
`;

const CustomNavbarToggle = styled(Navbar.Toggle)`
  padding: 16px 12px;
  color: ${palette.aqua};
  outline: 0 !important;
  border: 0;
  border-radius: 7px;
  cursor: pointer;
  background: linear-gradient(to top left, ${palette.black}, ${palette.darkgrey});
  box-shadow: 3px 3px 6px 0 #0d0d0d, -2px -2px 4px 0 #1c1c1c;

  &:hover {
    background: linear-gradient(to bottom right, ${palette.black}, ${palette.darkgrey});
  }

  &:focus {

  }
`;

const CustomNavbarTogglerIcon = styled.span`
  width: 24px; 
  height: 2px; 
  background-color: ${palette.aqua};
  display: block;
  position: relative;

  &::before,
  &::after {
    content: '';
    width: 24px;
    height: 2px;
    background-color: ${palette.aqua};
    display: block;
    position: absolute;
    left: 0;
    transition: all 0.3s ease-in-out;
  }

  &::before {
    top: -8px;
  }

  &::after {
    top: 8px;
  }
`;

const Links = () => {
  const navItems = [
    {
      title: 'news',
      links: [
        { to: 'https://news.google.com/topstories?hl=en-IE&gl=IE&ceid=IE:en', label: 'G News' },
        { to: 'https://www.inoreader.com/all_articles', label: 'Inoreader' },
        { to: 'https://www.wikitok.io', label: 'WikiTok' },
        { to: 'https://www.reddit.com', label: 'Reddit' },
        { to: 'https://www.engadget.com/', label: 'Engadget' },
        { to: 'https://www.theverge.com', label: 'The Verge' },
        { to: 'https://www.stuff.tv/', label: 'Stuff' },
        { to: 'https://www.wired.co.uk/', label: 'Wired' },
        { to: 'https://www.geeky-gadgets.com/', label: 'Geeky-Gadgets' },
        { to: 'https://www.theguardian.com', label: 'The Guardian' },
        { to: 'https://apnews.com/', label: 'AP News' },
        { to: 'https://www.reuters.com', label: 'Reuters' },
      ],
    },
    {
      title: 'work',
      links: [
        { to: 'https://portal.azure.com/#home', label: 'Microsoft Azure Portal' },
        { to: 'https://netwatch.visualstudio.com/CRATOS/', label: 'DevOps' },
        { to: 'https://chat.openai.com/chat', label: 'ChatGPT' },
        { to: 'https://www.timeanddate.com/', label: 'World Clock' },
        { to: 'https://crontab.guru/#0_9_*_*_*', label: 'Crontab.guru' },
        { to: 'https://netwatch.bamboohr.com/login.php?r=%2Fhome%2F', label: 'BambooHR' },
        { to: 'https://github.com/craigtupac-96?tab=repositories', label: 'GitHub' },
        { to: 'https://github.com/mtdvio/every-programmer-should-know', label: 'Programmer101' },
        { to: 'https://www.figma.com/files/recent?fuid=756072841721040524', label: 'Figma' },
        { to: 'https://www.lofi.cafe/', label: 'lofi.cafe' },
      ],
    },
    {
      title: 'media',
      links: [
        { to: 'https://www.youtube.com/', label: 'YouTube' },
        { to: 'https://www.calm.com/app', label: 'Calm' },
        { to: 'https://www.goloudplayer.com/radio/spin-1038', label: 'Spin 1038' },
        { to: 'https://www.icloud.com/', label: 'iCloud' },
        { to: 'https://www.disneyplus.com/en-gb/home', label: 'Disney+' },
        { to: 'https://www.netflix.com/browse', label: 'Netflix' },
        { to: 'https://www.audible.co.uk', label: 'Audible' },
        { to: 'https://app.daily.dev', label: 'Daily Dev' },
        { to: 'http://webtv.iptvsmarters.com/live.php', label: 'MyTv' },
        { to: 'https://comicbook.com/', label: 'ComicBook' },
        { to: 'https://www.justwatch.com', label: 'Just Watch' },
        { to: 'https://www.goodreads.com/', label: 'Goodreads' },
        { to: 'https://mail.google.com/mail/u/0/#inbox', label: 'Mail' },
        { to: 'https://drive.google.com/drive/u/0/my-drive', label: 'Drive' }
      ],
    },
    {
      title: 'gaming',
      links: [
        { to: 'https://store.playstation.com/en-ie/pages/latest', label: 'PS Store' },
        { to: 'https://psdeals.net/ie-store/', label: 'PS Deals' },
        { to: 'https://www.nintendo.co.uk/Search/Search-299117.html?f=147394-5-81', label: 'Nintendo EShop' },
        { to: 'https://ntdeals.net/ie-store', label: 'Nintendo Deals' },
        { to: 'https://limitedgamenews.com', label: 'Limited Game News' },
        { to: 'https://www.fangamer.com', label: 'Fan Gamer' },
        { to: 'https://signatureeditiongames.com/', label: 'Signature' },
        { to: 'https://limitedrungames.com/', label: 'Limited' },
        { to: 'https://www.play-asia.com/', label: 'Play Asia' },
        { to: 'https://www.g2a.com/', label: 'G2A' },
        { to: 'https://www.cdkeys.com/', label: 'CD Keys' },
      ],
    },
    {
      title: 'shopping',
      links: [
        { to: 'https://www.amazon.co.uk/', label: 'Amazon' },
        { to: 'https://www.adverts.ie/for-sale/consoles-games/134', label: 'Adverts' },
        { to: 'https://www.smythstoys.com/ie/en-ie/video-games', label: 'Smyths' },
        { to: 'https://ie.webuy.com/', label: 'CeX' },
        { to: 'https://zoom.co.uk/', label: 'Zoom' },
        { to: 'https://www.mygeekbox.co.uk/', label: 'My Geek Box' },
        { to: 'https://m.jdsports.ie/', label: 'JD' },
        { to: 'https://www.schuh.ie', label: 'Schuh' },
        { to: 'https://www.footlocker.ie', label: 'Foot Locker' },
        { to: 'https://www.littlewoodsireland.ie/', label: 'Very' },
        { to: 'https://www.mandmdirect.ie/', label: 'MandM' },
        { to: 'https://www.aliexpress.com/', label: 'AliExpress' },
        { to: 'https://ie.dhgate.com/', label: 'DHgate' },
      ],
    },
    {
      title: 'trading',
      links: [
        { to: 'https://www.kraken.com', label: 'Kraken' },
        { to: 'https://www.binance.com/en', label: 'Binance' },
        { to: 'https://www.trading212.com', label: 'Trading 212' },
        { to: 'https://www.ft.com', label: 'Financial Times' },
        { to: 'https://www.bloomberg.com/europe', label: 'Bloomberg' },
        { to: 'https://www.wsj.com', label: 'Wall Street Journal' },
        { to: 'https://cointelegraph.com', label: 'Coin Telegraph' },
        { to: 'https://coinmarketcap.com', label: 'Coin Market Cap' },
      ],
    },
    {
      title: 'misc',
      links: [
        { to: 'https://getcomics.info/', label: 'ComicInfo' },
        { to: 'https://www.z-epub.com/book/4196', label: 'Books' },
        { to: 'https://www.torrentdownloads.pro/', label: 'TorDown' },
        { to: 'https://m.thepiratebay10.org/', label: 'The Bay' },
        { to: 'http://www.yify-movies.net/', label: 'Yify' },
        { to: 'https://www.torlock.com/movies/1/size/desc.html', label: 'TorLock' },
        { to: 'https://prostylex.org/', label: 'Prosty' },
        { to: 'https://www.tunemymusic.com', label: 'Tune My Music' },
        { to: 'https://lteportal.imagine.ie/portal/login/login', label: 'Imagine' },
      ],
    },
  ];

  return (
    <>
      {navItems.map((navItem, index) => (
        <NeoNavbar key={index} collapseOnSelect expand={false} variant="dark">
          <Container>
            <LinkNavBrand>{navItem.title}</LinkNavBrand>
            <CustomNavbarToggle aria-controls={`responsive-navbar-nav-${index}`}>
              <CustomNavbarTogglerIcon />
            </CustomNavbarToggle>
            <Navbar.Collapse id={`responsive-navbar-nav-${index}`}>
              <Nav className="me-auto">
                {navItem.links.map((link, linkIndex) => (
                  <CustomNavLink key={linkIndex} as={Link} to={link.to}>
                    {link.label}
                  </CustomNavLink>
                ))}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </NeoNavbar>
      ))}
    </>
  );
};

export default Links;
